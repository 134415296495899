import { Injectable } from '@angular/core';

// Models
import { ICurrencyModel } from '@app/core/models/common.models';
import { IProposalAreaCustomItemModel, IProposalAreaFeeModel, IProposalAreaItemBaseModel, IProposalAreaItemIdTypeModel, IProposalAreaItemModel, IProposalAreaLaborModel, IProposalAreaModel, IProposalAreaOptionFinancialsModel, IProposalAreaOptionModel, IProposalAreaPartModel, IProposalChangeOrderDataModel, IProposalChangeOrderGroupModel, IProposalChangeOrderListModel, IProposalChangeOrderMetaModel, IProposalClientSettingsModel, IProposalConvenienceFeeModel, IProposalDealerModel, IProposalDetailClientModel, IProposalDetailModel, IProposalExpiryModel, IProposalFinancialsModel, IProposalItemChangeOrderAction, IProposalPaymentModel, IProposalPaymentScheduleItemModel, IProposalPaymentScheduleModel, IProposalRecurringServiceItemModel, IProposalRecurringServicesModel, IProposalTaxInformationModel, IProposalTaxModel, IProposalTaxRateModel } from '@app/core/models/proposal.models';

// Utilities
import { Utilities } from '@app/shared/utilities';

// Enums
import { AppSectionRefEnum, AreaItemTypeEnum, AreaOptionStatusEnum, ChangeOrderItemActionEnum, ContactTypeEnum, DiscountTypeEnum, GatewayTypeEnum, PaymentScheduleItemTypeEnum, PaymentScheduleMilestoneTypeEnum, PaymentSchedulePercentageBasisEnum, PaymentTypeEnum, ProposalStatusEnum, ProposalTaxApplyToEnum, TransactionStatusEnum } from '@app/core/enums';




// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
//
// = - = - = - = ProposalModelService
//
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -




@Injectable({
	providedIn: 'root',
})
export abstract class ProposalModelService {


	static getProposalAreaPartModel( item: any ): IProposalAreaPartModel {
		const baseItem = ProposalModelService.getProposalAreaItemBaseModel( item );

		return {
			...baseItem,

			model        : item?.['model'],
			supplierId   : +item?.['supplierId'],
			msrp         : item?.['msrp'],
			msrpDiscount : item?.['msrpDiscount'],
			brandName    : item?.['brand'],
			isPlaceholder: !!item?.['isPlaceholder'],
		};
	}


	static getProposalAreaLaborModel( item: any ): IProposalAreaLaborModel {
		const baseItem = ProposalModelService.getProposalAreaItemBaseModel( item );

		return {
			...baseItem,

			name              : item?.['name'],
			isRecurringService: !!item?.['isRecurringService'],
			isPlaceholder     : !!item?.['isPlaceholder'],
		};
	}


	static getProposalAreaCustomItemModel( item: any ): IProposalAreaCustomItemModel {
		const baseItem = ProposalModelService.getProposalAreaItemBaseModel( item );
		return {
			...baseItem,

			brand             : item?.['brand'],
			model             : item?.['model'],
			isRecurringService: !!item?.['isRecurringService'],
		};
	}


	static getProposalAreaFeeModel( item: any ): IProposalAreaFeeModel {
		const baseItem = ProposalModelService.getProposalAreaItemBaseModel( item );

		return {
			...baseItem,

			name              : item?.['name'],
			isRecurringService: !!item?.['isRecurringService'],
			isPlaceholder     : !!item?.['isPlaceholder'],
		};
	}


	static getProposalAreaItemBaseModel( item: any ) : IProposalAreaItemBaseModel {

		const changeOrderActions = ProposalModelService.getProposalItemChangeOrderActionModel( item?.['changeOrderTags'] );

		const model = {
			id                   : +item?.['id'],
			description          : item?.['shortDescription'],
			note                 : item?.['clientNote'],
			cost                 : +item?.['cost'] || 0,
			sellPrice            : +item?.['sellPrice'],
			quantity             : +item?.['quantity'],
			isTaxExempt          : !!item?.['isTaxExempt'],
			imageAssetId         : item?.['imageAssetId'],
			type                 : EnumModelService.getEnumValue( AreaItemTypeEnum, item?.['itemType'] ),
			parentId             : +item?.['parentId'] || 0,
			isCombinePrice       : !!item?.['total']?.['isCombinedPrice'],
			combinePriceValue    : item?.['total']?.['amount'],
			totalPrice           : null,
			referenceLineItemId  : Utilities.getIfNumericValue( item?.['referencedLineItemId'] ),
			changeOrderActions   : changeOrderActions,
			changeOrderActionType: EnumModelService.getEnumValue( ChangeOrderItemActionEnum, item?.['changeOrderAction'] ),	// NOTE: Used only from change order highlight API
		};

		if ( model?.isCombinePrice ) {
			model.totalPrice = model?.combinePriceValue;
		} else {
			model.totalPrice = model?.quantity * ( model?.sellPrice || 0 );
		}

		return model;
	}


	static getProposalItemChangeOrderActionModel( res: any ): IProposalItemChangeOrderAction[] {
		if ( !res?.length ) { return null; }

		const allChangeOrderActions: IProposalItemChangeOrderAction[] = [];

		for ( const action of res ) {
			const changeOrderAction = {
				id    : action?.['changeOrderId'],
				action: EnumModelService.getEnumValue( ChangeOrderItemActionEnum, action?.['changeOrderAction'] ),
				number: action?.['changeOrderNumber'],
			};

			allChangeOrderActions.push( changeOrderAction );
		}

		// Capture unique action elements
		const uniqueBasedOnKey: keyof IProposalItemChangeOrderAction = 'action';
		const uniqueChangeOrderActions = [ ...new Map( allChangeOrderActions?.map( item => [item[uniqueBasedOnKey], item]) ).values() ];

		return uniqueChangeOrderActions;
		// return allChangeOrderActions;
	}


	static getAreaItems( items: any ): IProposalAreaItemModel[] {
		if ( !items?.length ) { return []; }

		const outputItems: IProposalAreaItemModel[] = [];
		if ( items?.length ) {
			for ( const item of items ) {
				const itemModel = ProposalModelService.getProposalAreaItemModel( item );
				if ( itemModel ) {
					outputItems.push( itemModel );
				}
			}
		}

		return outputItems;
	}


	static getProposalAreaItemModel( item: any ): IProposalAreaItemModel {

		switch ( EnumModelService.getEnumValue( AreaItemTypeEnum, item?.['itemType'] )) {
			case AreaItemTypeEnum.Part:
				return ProposalModelService.getProposalAreaPartModel( item );

			case AreaItemTypeEnum.Labor:
				return ProposalModelService.getProposalAreaLaborModel( item );

			case AreaItemTypeEnum.Custom:
				return ProposalModelService.getProposalAreaCustomItemModel( item );

			case AreaItemTypeEnum.Fee:
				return ProposalModelService.getProposalAreaFeeModel( item );
		}

		return null;
	}


	static getProposalAreaItemIdTypeModel( data: any ): IProposalAreaItemIdTypeModel {
		if ( !data ) { return null; }

		return {
			id      : +data?.['id'],
			type    : EnumModelService.getEnumValue( AreaItemTypeEnum, data?.['itemType'] ),
			parentId: +data?.['parentId'] || 0,
		};
	}


	static getProposalAreaItemIdTypeModels( res: any ): IProposalAreaItemIdTypeModel[] {
		if ( !res ) { return []; }

		const items: IProposalAreaItemIdTypeModel[] = [];

		// Build items array
		if ( res?.length ) {
			for ( const item of res ) {
				items?.push( ProposalModelService.getProposalAreaItemIdTypeModel( item ) );
			}
		}

		return items;
	}


	static getProposalAreaModel( data: any ): IProposalAreaModel {
		const options: IProposalAreaOptionModel[] = [];

		if ( data?.['areaOptions'] ) {
			for ( const option of data?.['areaOptions'] ) {

				const items = ProposalModelService.getProposalAreaItemIdTypeModels( option?.['items'] );

				// Build changeOrderData
				const changeOrderData = {};
				if ( option?.['changeOrderItems']?.length ) {
					for ( const changeOrderItem of option?.['changeOrderItems'] ) {
						const changeOrderDataModel = ProposalModelService.getProposalChangeOrderDataModel( changeOrderItem );
						if ( changeOrderDataModel?.id ) {
							changeOrderData[ changeOrderDataModel?.id ] = changeOrderDataModel;
						}
					}
				}

				options.push({
					id         	          : +option?.['id'],
					status     	          : EnumModelService.getEnumValue( AreaOptionStatusEnum, option?.['status'] ),
					items      	          : items,
					changeOrderData       : changeOrderData,
					description	          : option?.['clientDescription'],
					financials 	          : ProposalModelService.getProposalAreaOptionFinancialsModel( option ),
					clientLastDecisionDate: option?.['clientLastDecision'],
				});
			}
		}

		return {
			id         : +data?.['id'],
			name       : data?.['name'],
			options    : options,
		};
	}


	static getProposalChangeOrderDataModel( res: any ): IProposalChangeOrderDataModel {
		if ( !res ) { return null; }

		const items: IProposalChangeOrderDataModel['items'] = [];

		if ( res?.['items']?.length ) {
			for ( const item of res?.['items'] ) {
				// Assign MiscChanges if changeOrderAction is not found so they are shown at the bottom of the list in a group
				if ( !item?.['changeOrderAction'] ) {
					// Change order action not found - make it misc changes
					item['changeOrderAction'] = ChangeOrderItemActionEnum.MiscChanges;
				}

				const itemModel = ProposalModelService.getProposalAreaItemModel( item );

				if ( itemModel ) {
					items.push( itemModel );
				}
			}
		}

		const areaOptionFinancialModel = ProposalModelService.getProposalAreaOptionFinancialsModel( res );

		return {
			id               : res['changeOrderId'],
			changeOrderNumber: res['changeOrderNumber'],
			items            : items,

			...areaOptionFinancialModel,
		};
	}


	static getProposalAreaOptionFinancialsModel( res: any ): IProposalAreaOptionFinancialsModel {
		if ( !res ) { return null; }

		return {
			areaOptionTotal      : +res?.['total'] || 0,
			recurringServiceTotal: +res?.['totalRecurringService'],
		};
	}


	static getProposalPaymentModel( res: any ): IProposalPaymentModel {
		if ( !res ) { return null; }

		const resPayment = res;
		const paymentTypes: PaymentTypeEnum[] = [];
		if ( resPayment?.['availablePaymentTypes'] ) {
			for ( const type of resPayment?.['availablePaymentTypes'] ) {
				const compatibleType = EnumModelService.getEnumValue( PaymentTypeEnum, type );
				if ( compatibleType ) {
					paymentTypes.push( compatibleType );
				}
			}
		}

		return {
			id          	     : +resPayment?.['id'],
			number      	     : resPayment?.['number'],
			status      	     : EnumModelService.getEnumValue( TransactionStatusEnum, resPayment?.['status'] ),
			amount      	     : +resPayment?.['amount'],
			paymentTypes	     : paymentTypes,
			paymentUrl  	     : resPayment?.['paymentUrl'],
			description 	     : resPayment?.['description'],
			dueDate     	     : Utilities.getDateWithoutTimezone( resPayment?.['dueDate'] ),
			paymentDate 	     : Utilities.getDateWithoutTimezone( resPayment?.['paidDate'] ) || null,
			paymentInitiatedDate : Utilities.getDateWithoutTimezone( resPayment?.['initiatedPaymentDate'] ) || null,
			paidVia 	         : resPayment?.['paidVia'] || '',
			paymentProcessor     : EnumModelService.getEnumValue( GatewayTypeEnum, resPayment?.['paymentProcessorType'], GatewayTypeEnum.Custom  ),	// Default it to Custom
		};
	}


	static getProposalPaymentModels( res: any ): IProposalPaymentModel[] {
		if ( !res ) { return []; }

		const payments: IProposalPaymentModel[] = [];

		for ( const payment of res ) {
			const compatibleType = ProposalModelService.getProposalPaymentModel( payment );
			if ( compatibleType ) {
				payments.push( compatibleType );
			}
		}

		return payments;
	}


	static getProposalTaxApplyToEnum( types: string[] ): ProposalTaxApplyToEnum[] {
		if ( !types?.length ) { return null; }

		const responseTaxTypes = types.map( item => item?.toUpperCase() );

		const applyTo: ProposalTaxApplyToEnum[] = [];

		responseTaxTypes?.forEach( type => {
			switch ( type ) {
				case 'PARTS':
					applyTo.push( ProposalTaxApplyToEnum.Part );
					break;

				case 'LABOR':
					applyTo.push( ProposalTaxApplyToEnum.Labor );
					break;

				case 'FEE':
					applyTo.push( ProposalTaxApplyToEnum.Fee );
					break;
			}
		});

		return applyTo;
	}


	static getProposalFinancialsModel( financial: any, recurringServices: any ): IProposalFinancialsModel {
		if ( !financial ) { return null; }

		return {
			partsValue               : Utilities.getIfNumericValue( financial?.['partsSubtotal'] ),
			discountAmount           : Utilities.getIfNumericValue( financial?.['partsDiscount'] ),
			discountPercent          : Utilities.getIfNumericValue( financial?.['partsDiscountPercentage'] ),
			discountType             : EnumModelService.getEnumValue( DiscountTypeEnum, financial?.['partsDiscountType'] ),
			totalMsrpDiscount        : Utilities.getIfNumericValue( financial?.['partsMsrpDiscount'] ),
			partsTotal               : Utilities.getIfNumericValue( financial?.['partsTotal'] ),
			laborTotal               : Utilities.getIfNumericValue( financial?.['laborTotal'] ),
			feesTotal                : Utilities.getIfNumericValue( financial?.['feeTotal'] ),
			grandTotal               : Utilities.getIfNumericValue( financial?.['proposalTotal'] ),
			taxDetails               : ProposalModelService.getProposalTaxModel( financial?.['salesTax'] ),
			convenienceFees          : ProposalModelService.getProposalConvenienceFeesModel( financial?.['convenienceFees'] ),
			recurringServices        : ProposalModelService.getProposalRecurringServicesModel( recurringServices ),
			projectTotal             : Utilities.getIfNumericValue( financial?.['projectTotal'] ),
			acceptedChangeOrdersTotal: Utilities.getIfNumericValue( financial?.['acceptedChangeOrderTotal'] ),
			projectBalance           : Utilities.getIfNumericValue( financial?.['projectBalance'] ),
			paidPaymentRequestTotal  : Utilities.getIfNumericValue( financial?.['paidRequestTotal'] ),
		};
	}


	static getProposalTaxModel( res: any ): IProposalTaxModel {
		if ( !res ) { return null; }

		return {
			taxAmount      : res?.['total'],
			partsTotalTax  : Utilities.getIfNumericValue( res?.['calculation']?.['partsTotalTax'] ),
			laborTotalTax  : Utilities.getIfNumericValue( res?.['calculation']?.['laborTotalTax'] ),
			feesTotalTax   : Utilities.getIfNumericValue( res?.['calculation']?.['feeTotalTax'] ),
			taxInformation : ProposalModelService.getProposalTaxInformationModel( res?.['calculation'] ),
		};
	}


	static getProposalTaxInformationModel( res: any ): IProposalTaxInformationModel {
		if ( !res ) { return null; }

		const taxInformation = ProposalModelService.getTaxModel( {
			taxPartInfo1     : ProposalModelService.getTaxRateModel( res?.['partsTax'], res?.['partsTaxName'] ),
			taxPartInfo2     : ProposalModelService.getTaxRateModel( res?.['partsTax2'], res?.['partsTax2Name'] ),
			taxLaborInfo1    : ProposalModelService.getTaxRateModel( res?.['laborTax'], res?.['laborTaxName'] ),
			taxLaborInfo2    : ProposalModelService.getTaxRateModel( res?.['laborTax2'], res?.['laborTax2Name'] ),
			taxFeeInfo1      : ProposalModelService.getTaxRateModel( res?.['feeTax'], res?.['feeTaxName'] ),
			taxFeeInfo2      : ProposalModelService.getTaxRateModel( res?.['feeTax2'], res?.['feeTax2Name'] ),
			applyTo          : res?.['applyTo'],
		} );

		return taxInformation;
	}


	static getTaxModel({ taxPartInfo1, taxPartInfo2, taxLaborInfo1, taxLaborInfo2, taxFeeInfo1, taxFeeInfo2, applyTo }): IProposalTaxInformationModel {

		return {
			applyTo      : ProposalModelService.getProposalTaxApplyToEnum( applyTo ),
			partTaxInfo1 : taxPartInfo1,
			partTaxInfo2 : taxPartInfo2,
			laborTaxInfo1: taxLaborInfo1,
			laborTaxInfo2: taxLaborInfo2,
			feeTaxInfo1  : taxFeeInfo1,
			feeTaxInfo2  : taxFeeInfo2,
		};
	}


	static getTaxRateModel( taxRate: number, taxName: string ): IProposalTaxRateModel {
		return {
			taxName: taxName,
			taxRate: taxRate,
		};
	}


	static getProposalConvenienceFeesModel( res: any ): IProposalConvenienceFeeModel[] {
		if ( !res ) { return null; }

		const output: IProposalConvenienceFeeModel[] = [];
		for ( const fee of res ) {
			const _fee = ProposalModelService.getProposalConvenienceFeeModel( fee );
			if ( _fee ) {
				output.push( _fee );
			}
		}

		return output;
	}


	static getProposalConvenienceFeeModel( res: any ): IProposalConvenienceFeeModel {
		if ( !res ) { return null; }

		return {
			amount       : Utilities.getIfNumericValue( res?.['amount'] ),
			invoiceType  : res?.['invoiceType']?.toUpperCase() === 'REQ' ? AppSectionRefEnum.PaymentRequest : AppSectionRefEnum.Invoice,
			invoiceNumber: res?.['invoiceNumber'],
			isTaxExempt  : !!res?.['isTaxExempt'],
		};
	}


	static getProposalRecurringServicesModel( res: any ): IProposalRecurringServicesModel {
		if ( !res ) { return null; }

		return {
			total: res?.['totalRecurringService'],
			items: ProposalModelService.getProposalRecurringServiceItemModels( res?.['items'] ),
		};
	}


	static getProposalRecurringServiceItemModels( res: any ): IProposalRecurringServiceItemModel[] {
		if ( !res?.length ) { return []; }

		const recurringServiceItems: IProposalRecurringServiceItemModel[] = [];
		for ( const recurringServiceItem of res ) {
			const item = ProposalModelService.getProposalRecurringServiceItemModel( recurringServiceItem );
			if ( item ) {
				recurringServiceItems.push( item );
			}
		}

		return recurringServiceItems;
	}


	static getProposalRecurringServiceItemModel( res: any ): IProposalRecurringServiceItemModel {
		if ( !res ) { return null; }

		return {
			name    : res?.['name'],
			total   : res?.['sellPrice'],
			quantity: res?.['quantity'],
		};
	}


	static getProposalPaymentScheduleItemModels( res: any ): IProposalPaymentScheduleItemModel[] {
		if ( !res?.length ) { return []; }

		const items = [];

		for ( const item of res ) {
			if ( item ) {
				items.push( ProposalModelService.getProposalPaymentScheduleItemModel( item ) );
			}
		}

		return items;
	}


	static getProposalPaymentScheduleItemModel( res: any ): IProposalPaymentScheduleItemModel {
		if ( !res ) { return null; }

		return {
			id               : res?.['id'],
			amount           : res?.['amount'],
			milestoneId      : res?.['milestoneId'],
			milestone        : res?.['milestone'],
			milestoneType    : EnumModelService.getEnumValue( PaymentScheduleMilestoneTypeEnum, res?.['milestoneType'] ),
			percentageBasis  : EnumModelService.getEnumValue( PaymentSchedulePercentageBasisEnum, res?.['percentageBasis'] ),
			percent          : res?.['percent'],
			dueDate          : res?.['dueDate'],
			itemType         : EnumModelService.getEnumValue( PaymentScheduleItemTypeEnum, res?.['paymentScheduleItemType'] ),
		};
	}


	static getProposalExpiryModel( res: any ): IProposalExpiryModel {
		if ( !res ) { return null; }

		return {
			date: Utilities.getDateWithoutTimezone( res?.['expiryDate'] ) ?? null,
			days: res?.['expiry'] ?? null,
		};
	}


	static getAffectedOptionFinancials( res: any ): { [key: number]: IProposalAreaOptionFinancialsModel } {	// Key is OptionId
		if ( !res ) { return null; }

		// Key is optionId
		const affectedOptionFinancials: { [key: number]: IProposalAreaOptionFinancialsModel } = {};

		for ( const optionFinanceData of res ) {
			const optionId = optionFinanceData?.['id'];
			affectedOptionFinancials[ optionId ] = ProposalModelService.getProposalAreaOptionFinancialsModel( optionFinanceData );
		}

		return affectedOptionFinancials;
	}


	static getProposalDetailModel( res: any ): IProposalDetailModel {

		const [ areas, allItems ] = ProposalModelService.getProposalAreaAndAreaItemModel( res?.['areas'] );

		const changeOrderFinancials: { [key: number]: IProposalFinancialsModel } = {};
		if ( res?.['changeOrderFinancialSummary'] ) {
			for ( const changeOrderFinanceData of res?.['changeOrderFinancialSummary'] ) {
				const changeOrderId = changeOrderFinanceData?.['changeOrderId'];
				changeOrderFinancials[ changeOrderId ] = ProposalModelService.getProposalFinancialsModel( changeOrderFinanceData, res?.['recurringServices'] );
			}
		}

		const changeOrderMeta: IProposalChangeOrderMetaModel = {
			parentProposalId  : res['parentProposalId'],
			parentProposalName: res['parentProposalName'],
			changeOrderNumber : res['changeOrderNumber'],
		};

		const output: IProposalDetailModel = {
			id                        : +res?.['id'],
			name                      : res?.['name'],
			number                    : res?.['number'],
			status                    : EnumModelService.getEnumValue( ProposalStatusEnum, res?.['status'] ),
			aboutUs                   : res?.['aboutUs'],
			projectDescription        : res?.['projectDescription'],
			paymentSchedule           : ProposalModelService.getProposalPaymentScheduleModel( res?.['paymentSchedule'] ),
			projectTerms              : res?.['projectTerms'],
			client                    : ProposalModelService.getProposalDetailClientModel( res?.['customer'] ),
			coverImageAssetId         : res?.['coverImageAssetId'],
			logoImageAssetId          : res?.['dealer']?.['companyLogoId'],
			clientSettings            : ProposalModelService.getProposalClientSettingsModel( res?.['clientViewSettings'] ),
			areas                     : areas,
			allItems                  : allItems,
			dealer                    : ProposalModelService.getProposalDealerModel( res?.['dealer'] ),
			clientAcceptOrDeclineDate : res?.['proposalDates']?.['clientLastDecision'],	// Already Formatted date string
			completedDate             : res?.['proposalDates']?.['lastCompleted'],
			payments                  : ProposalModelService.getProposalPaymentModels( res?.['paymentRequests'] ),
			expiry                    : ProposalModelService.getProposalExpiryModel( res?.['expiry'] ),
			isPaymentProcessingEnabled: !!res?.['isPaymentProcessingEnabled'],
			isPreview                 : !!res?.['isPreview'],
			financials                : ProposalModelService.getProposalFinancialsModel( res?.['financialSummary'], res?.['recurringServices'] ),
			changeOrderFinancials     : changeOrderFinancials,
			changeOrderMeta           : changeOrderMeta,
			isChangeOrder             : !!res?.['parentProposalId'],
			currency                  : CommonModelService.getCurrencyModel( res?.['financialSummary']?.['currency']?.['format'], res?.['financialSummary']?.['currency']?.['symbol'] ),
		};

		return output;
	}


	static getProposalAreaAndAreaItemModel( res: any ): [ IProposalAreaModel[], IProposalAreaItemModel[] ] {
		if ( !res?.length ) { return [[], []]; }

		const areas   : IProposalAreaModel[]     = [];
		const allItems: IProposalAreaItemModel[] = [];

		// Build rooms data with parts, labors, fees and custom Items
		for ( const area of res ) {
			areas.push( ProposalModelService.getProposalAreaModel( area ) );
			if ( area?.['areaOptions']?.length ) {
				for ( const option of area?.['areaOptions'] ) {
					allItems.push( ...ProposalModelService.getAreaItems( option?.['items'] ) );

					const changeOrderItems = ProposalModelService.getChangeOrderItems( option?.['changeOrderItems'] );
					if ( changeOrderItems?.length ) {
						allItems.push( ...changeOrderItems );
					}
				}
			}
		}

		return [ areas, allItems ];
	}


	static getChangeOrderItems( res: any ): IProposalAreaItemModel[] {
		if ( !res ) { return []; }

		const rawChangeOrders = res;
		const newItems        = [];

		// Loop through multiple changes orders
		rawChangeOrders?.forEach( changeOrder => {
			const rawChangeOrderItems = changeOrder?.['items'];
			if ( rawChangeOrderItems ) {

				const rawItems = [];
				// Grab change order items and add change order tags so the structure is compatible with the rest of the app
				rawChangeOrderItems.forEach( item => {
					item['changeOrderTags'] = [{
						'changeOrderAction': item?.['changeOrderAction'],
						'changeOrderId'    : changeOrder?.['changeOrderId'],
						'changeOrderNumber': changeOrder?.['changeOrderNumber'],
					}];
					rawItems.push( item );
				});

				const changeOrderItems = rawItems ? ProposalModelService.getAreaItems( rawItems ) : [];

				// Push change order items at the bottom of allItems array
				if ( changeOrderItems ) {
					newItems.push( ...changeOrderItems );
				}
			}
		});

		return newItems;
	}


	static getProposalDetailClientModel( res: any ) : IProposalDetailClientModel {
		if ( !res ) { return null; }

		const customerLocation = res?.['location'];

		return {
			id               : res?.['id'],
			email            : res?.['contactEmail'],
			name             : ( res?.['firstName'] + ' ' + res?.['lastName'] )?.trim(),
			displayName      : res?.['displayName'],
			companyName      : res?.['companyName'],
			contactType      : EnumModelService.getEnumValue( ContactTypeEnum, res?.['contactType'] ),
			phone            : res?.['contactPhone']?.['phoneNumber'],
			countryName      : customerLocation?.['country'],
			street           : customerLocation?.['street'],
			suite            : customerLocation?.['suite'],
			city             : customerLocation?.['city'],
			stateAbbreviation: customerLocation?.['stateAbbrev'],
			postalCode       : customerLocation?.['postalCode'],
		};
	}


	static getProposalClientSettingsModel( res: any ): IProposalClientSettingsModel {
		if ( !res ) { return null; }

		return {
			hideAreaTotals       : !!res?.['hideAreaTotals'],
			hideImages           : !!res?.['hideImages'],
			hideCompanyAddress   : !!res?.['hideCompanyAddress'],
			hideMsrpDiscounts    : !!res?.['hideItemDiscount'],
			hideItemPrices       : !!res?.['hideItemPrices'],
			hideFeePrices        : !!res?.['hideFeePrices'],
			hideLaborRateQtyTotal: !!res?.['hideLaborPrices'],
			hideLaborRateQty     : !!res?.['hideLaborRateAndQty'],
			hideLaborTotal       : !!res?.['hideLaborTotal'],
			hideModelNumbers     : !!res?.['hideModelNumbers'],
			viewSimpleProposal   : !!res?.['simpleView'],
		};
	}


	static getProposalPaymentScheduleModel( res: any ): IProposalPaymentScheduleModel {
		if ( !res ) { return null; }

		return {
			description: res?.['customerDescription'],
			items      : ProposalModelService.getProposalPaymentScheduleItemModels( res?.['payments'] ),
		};
	}


	static getProposalDealerModel( res: any ): IProposalDealerModel {
		if ( !res ) { return null; }

		const location    = res?.['location'];
		const salesPerson = res?.['salesperson'];

		return {
			id               : +res?.['id'],
			name             : ( salesPerson?.['firstName'] + ' ' + salesPerson?.['lastName'] )?.trim(),
			email            : salesPerson?.['email'],
			license          : res?.['license'],
			companyName      : res?.['companyName'],
			websiteUrl       : res?.['webSiteUrl'],
			countryName      : location?.['country'],
			street           : location?.['street'],
			suite            : location?.['suite'],
			city             : location?.['city'],
			stateAbbreviation: location?.['stateAbbrev'],
			postalCode       : location?.['postalCode'],
			phone            : res?.['companyPhone']?.['phoneNumber'],
		};
	}


	static getProposalChangeOrderGroupModel( res: any ): IProposalChangeOrderGroupModel {
		if ( !res ) { return null; }

		// Accepted Change Orders
		const acceptedChangeOrders: IProposalChangeOrderListModel[] = res?.['data']?.map( item => ProposalModelService.getProposalChangeOrderListModel( item ) ) ?? [];

		// Pending Change Orders
		const pendingChangeOrders: IProposalChangeOrderListModel[] = res?.['pendingItems']?.map( item => ProposalModelService.getProposalChangeOrderListModel( item ) ) ?? [];

		return { acceptedChangeOrders, pendingChangeOrders };
	}


	static getProposalChangeOrderListModel( res: any ): IProposalChangeOrderListModel {
		if ( !res ) { return null; }

		return {
			id          : res?.['id'],
			name        : res?.['name'],
			proposalId  : res?.['number'],
			number      : res?.['changeOrderNumber'],
			acceptedDate: res?.['proposalDates']?.['clientLastDecision'] ?? null,
			amount      : Utilities.getIfNumericValue( res?.['total']?.['proposalTotal'] ),
			previewUrl  : res?.['previewUrl'],
			status      : EnumModelService.getEnumValue( ProposalStatusEnum, res?.['status'] ),
		};
	}

}




// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
//
// = - = - = - = CommonModelService
//
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -




@Injectable({
	providedIn: 'root',
})
export abstract class CommonModelService {

	static getCurrencyModel( format: string, symbol: string ): ICurrencyModel {
		return { format, symbol };
	}

}




// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
//
// = - = - = - = EnumModelService
//
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -




@Injectable({
	providedIn: 'root',
})
export abstract class EnumModelService {


	constructor() { }


	static existsInEnum<K>( en: K, value: number | string ): boolean {
		if ( typeof value === 'string' ) {
			return Object.values( en ).map( v => v?.toUpperCase() ).includes( value?.toUpperCase() );
		}

		return Object.values( en ).includes( value );
	}


	// If enum value exists - return the key, else optionally return default sent value
	static getEnumValue<tEnum>( en: tEnum, value: number | string, returnDefaultEnum?: tEnum[keyof tEnum] ): tEnum[keyof tEnum] {
		if ( EnumModelService.existsInEnum( en, value ) ) {
			if ( typeof value === 'string' ) {
				// String - match using uppercase
				return Object.values( en ).find( v => v?.toUpperCase() === value?.toUpperCase() );
			}

			return Object.values( en ).find( v => v === value );
		}

		if ( returnDefaultEnum ) {
			return returnDefaultEnum;
		}

		return null;
	}

}
