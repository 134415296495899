import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnChanges, inject, SimpleChanges } from '@angular/core';

// Models
import { ICurrencyModel } from '@app/core/models/common.models';
import { IProposalClientSettingsModel, IProposalConvenienceFeeModel, IProposalFinancialsModel, IProposalRecurringServiceItemModel } from '@app/core/models/proposal.models';

// Utilities
import { Utilities } from '@app/shared/utilities';

// Services
import { ChangeOrderService } from '@app/proposals/services/change-order.service';

// Enums
import { DiscountTypeEnum, ProposalTaxApplyToEnum } from '@app/core/enums';

// Constants
import { LinkAbbr } from '@app/shared/constants';


@Component({
	selector       : 'app-financial-summary',
	templateUrl    : './financial-summary.component.html',
	styleUrls      : ['./financial-summary.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone     : false,
})
export class FinancialSummaryComponent implements OnInit, OnChanges {
	public readonly changeOrderService = inject( ChangeOrderService );
	private readonly cd                = inject( ChangeDetectorRef );
	public readonly utils              = inject( Utilities );

	@Input() loader            : boolean                      = false;
	@Input() clientSettings    : IProposalClientSettingsModel = null;
	@Input() financials        : IProposalFinancialsModel     = null;
	@Input() currency          : ICurrencyModel               = null;
	@Input() viewSimpleProposal: boolean                      = false;
	@Input() isHighlightsShown : boolean                      = false;

	readonly DiscountTypeEnum = DiscountTypeEnum;
	readonly LinkAbbr         = LinkAbbr;

	changeOrderFinancials: IProposalFinancialsModel;

	private grandTotal   : number;
	partsTotal           : number;
	laborTotal           : number;
	feesTotal            : number;

	// private partsTaxPercent   : number;
	// private laborTaxPercent   : number;
	// partsTotalTax             : number;
	// laborTotalTax             : number;
	private partsWithoutDiscount : number;
	private partsDiscount        : number;
	private taxAmount            : number;
	private recurringServiceTotal: number;
	recurringServiceItems        : IProposalRecurringServiceItemModel[];
	private totalMsrpDiscount    : number;
	simplifiedTax				 : number;
	convenienceFees              : IProposalConvenienceFeeModel[];


	ngOnInit(): void {
		this.changeOrderService.changeTrigger$.subscribe( () => {
			this.updateFinancials();

			this.markForChange();
		});
	}


	ngOnChanges( changes: SimpleChanges ): void {
		this.updateFinancials();
	}


	private updateFinancials(): void {

		const financials = this.financials;

		this.grandTotal            = financials?.grandTotal;
		this.partsTotal            = financials?.partsTotal;
		this.laborTotal            = financials?.laborTotal;
		this.feesTotal             = financials?.feesTotal;
		this.partsWithoutDiscount  = financials?.partsValue;
		this.partsDiscount         = financials?.discountAmount;
		this.taxAmount             = financials?.taxDetails?.taxAmount;
		// this.partsTotalTax         = financials?.taxDetails?.partsTotalTax;
		// this.laborTotalTax         = financials?.taxDetails?.laborTotalTax;
		this.recurringServiceTotal = financials?.recurringServices?.total;
		this.recurringServiceItems = financials?.recurringServices?.items;
		this.totalMsrpDiscount     = financials?.totalMsrpDiscount;
		this.convenienceFees       = financials.convenienceFees;

		this.changeOrderFinancials = this.changeOrderService.getCheckedChangeOrderFinancials();

		// Check if simplified tax should be shown
		this.checkForShowingSimplifiedTax();
	}


	get calculatedGrandTotal(): number {
		return this.grandTotal + this.changeOrderFinancials?.grandTotal;
	}


	get calculatedPartsTotal(): number {
		return this.partsTotal + this.changeOrderFinancials?.partsTotal;
	}


	get calculatedLaborTotal(): number {
		return this.laborTotal + this.changeOrderFinancials?.laborTotal;
	}


	get calculatedFeesTotal(): number {
		return this.feesTotal + this.changeOrderFinancials?.feesTotal;
	}


	get calculatedPartsWithoutDiscount(): number {
		return this.partsWithoutDiscount + this.changeOrderFinancials?.partsValue;
	}


	get calculatedPartsDiscount(): number {
		return this.partsDiscount + this.changeOrderFinancials?.discountAmount;
	}


	get calculatedTaxAmount(): number {
		return this.taxAmount + this.changeOrderFinancials?.taxDetails.taxAmount;
	}


	get calculatedTotalMsrpDiscount(): number {
		return this.totalMsrpDiscount + this.changeOrderFinancials?.totalMsrpDiscount;
	}


	get calculatedRecurringServiceTotal(): number {
		return this.recurringServiceTotal + this.changeOrderFinancials?.recurringServices?.total;
	}


	get curFormat(): string {
		return this.currency?.format;
	}


	get curSymbol(): string {
		return this?.currency?.symbol;
	}


	get showPartTax(): boolean {
		return this.financials?.taxDetails?.taxInformation?.applyTo?.includes( ProposalTaxApplyToEnum.Part ) && ( this.utils.isValueDefined( this.partsTotal ) || this.utils.isValueDefined( this.changeOrderFinancials?.partsTotal ) );
	}


	get showLaborTax(): boolean {
		return this.financials?.taxDetails?.taxInformation?.applyTo?.includes( ProposalTaxApplyToEnum.Labor ) && ( this.utils.isValueDefined( this.laborTotal ) || this.utils.isValueDefined( this.changeOrderFinancials?.laborTotal ) );
	}


	get showFeesTax(): boolean {
		return this.financials?.taxDetails?.taxInformation?.applyTo?.includes( ProposalTaxApplyToEnum.Fee ) && ( this.utils.isValueDefined( this.feesTotal ) || this.utils.isValueDefined( this.changeOrderFinancials?.feesTotal ));
	}


	// Check if all tax rates are same to show one simplied tax rate
	private checkForShowingSimplifiedTax(): void {
		// Reset value
		this.simplifiedTax = null;

		const taxInformation = this.financials?.taxDetails?.taxInformation;

		// Get tax rate values if their respective item types are present in proposal - otherwise set to null
		const partsTax1 = this.showPartTax ? taxInformation?.partTaxInfo1?.taxRate   : null;
		const laborTax1 = this.showLaborTax ? taxInformation?.laborTaxInfo1?.taxRate : null;
		const feesTax1  = this.showFeesTax ? taxInformation?.feeTaxInfo1?.taxRate    : null;
		const partsTax2 = this.showPartTax ? taxInformation?.partTaxInfo2?.taxRate   : null;
		const laborTax2 = this.showLaborTax ? taxInformation?.laborTaxInfo2?.taxRate : null;
		const feesTax2  = this.showFeesTax ? taxInformation?.feeTaxInfo2?.taxRate    : null;

		// Get non-null values
		const values = [ partsTax1, laborTax1, feesTax1, partsTax2, laborTax2, feesTax2 ].filter( this.utils.isValueDefined );

		// If all values are same - set a value to show one simplified tax rate
		if ( values?.length > 0 && values?.every( value => value === values[0] ) ) {
			this.simplifiedTax = values[0];
		}
	}


	// Mark for change detection
	private markForChange(): void {
		this.cd.markForCheck();
	}

}
