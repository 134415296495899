import { Directive, ElementRef, Renderer2, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';


// NOTE::: This directive has been pulled and slightly modified from a cloudinary angular PR. In future it will be part of main package
// DIRECTIVE AVAILABLE: https://github.com/cloudinary/cloudinary_angular/blob/master/projects/angular-cld/src/lib/cloudinary-background-image.directive.ts


@Directive({
	selector: '[customClBackgroundImage]',
	standalone: false,
})
export class CloudinaryBackgroundImageDirective implements AfterViewInit, OnChanges {

	@Input() customClBackgroundImage: string;


	constructor(
		private renderer: Renderer2,
		private el      : ElementRef,
	) { }


	private isBrowser(): boolean {
		return typeof window !== 'undefined';
	}


	ngOnChanges( changes: SimpleChanges ): void {
		if ( changes?.['customClBackgroundImage'] && !changes?.['customClBackgroundImage']?.firstChange ) {
			this.bindImageUrl();
		}
	}


	ngAfterViewInit(): void {
		this.bindImageUrl();
	}


	private bindImageUrl(): void {
		if ( this.isBrowser() ) {
			const nativeElement = this.el.nativeElement;

			this.renderer.setStyle( nativeElement, 'background-image', `url('${this.customClBackgroundImage}')` );
			this.renderer.setStyle( nativeElement, 'background-repeat', 'no-repeat' );
			this.renderer.setStyle( nativeElement, 'background-position', 'center' );
		}
	}
}
