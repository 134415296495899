import { Component, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

// Utility
import { Utilities } from '@app/shared/utilities';

// Services
import { ProposalService } from '@app/core/services/proposal.service';
import { ChangeOrderService } from '@app/proposals/services/change-order.service';
import { ProposalCommonService } from '@app/proposals/services/proposal-common.service';

// Models
import { ErrorModel } from '@app/core/models/error.model';
import {
	IProposalAreaModel,
	IProposalClientSettingsModel,
	IProposalAreaItemModel,
	IProposalAreaOptionModel,
	IProposalAreaItemIdTypeModel,
	IProposalDetailModel,
} from '@app/core/models/proposal.models';
import { ICurrencyModel } from '@app/core/models/common.models';

// Enums
import { AreaItemTypeEnum, AreaOptionStatusEnum, ProposalStatusEnum } from '@app/core/enums';


@Component({
	selector       : 'app-proposal-room',
	templateUrl    : './proposal-room.component.html',
	styleUrls      : ['./proposal-room.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone     : false,
})
export class ProposalRoomComponent implements OnDestroy, OnChanges {
	private readonly cd                   = inject( ChangeDetectorRef );
	public readonly proposalCommonService = inject( ProposalCommonService );
	private readonly proposalService      = inject( ProposalService );
	public readonly changeOrderService    = inject( ChangeOrderService );
	public readonly utils                 = inject( Utilities );

	@ViewChild('optionalItemTabs') optionalItemTabs: TabsetComponent;

	@Input() area          : IProposalAreaModel;
	@Input() allItems      : IProposalAreaItemModel[] = [];
	@Input() clientSettings: IProposalClientSettingsModel;
	@Input() activeOptionId: number                   = null;
	@Input() token         : string                   = null;
	@Input() isPreview     : boolean                  = false;
	@Input() proposalStatus: IProposalDetailModel['status'] = null;
	@Input() currency      : ICurrencyModel;
	@Input() isSimpleView  : boolean = false;

	@Output() eventActiveOption = new EventEmitter<number>();
	@Output() eventReloadParent = new EventEmitter<null>();

	readonly ProposalStatusEnum = ProposalStatusEnum;

	readonly AreaItemTypeEnum     = AreaItemTypeEnum;		// Needed here to template
	readonly AreaOptionStatusEnum = AreaOptionStatusEnum;		// Needed here to template
	parentItemIdTypesMap = new Map<number, IProposalAreaItemIdTypeModel[]>();

	clientSelectedOption       : number               = 0;
	activeOptionCount          : number               = 0;
	optionTotalAmount          : number               = 0;
	activeOptionStatus         : AreaOptionStatusEnum = AreaOptionStatusEnum.Draft;
	optionRecurringServiceTotal: number               = 0;

	readonly states = {
		confirmingOptions: false,
	};

	readonly loaderStates = {
		area: false,
	};

	readonly proposalRoomItemTokens: {
		itemIdType   : IProposalAreaItemIdTypeModel,
		showMiscLabel: boolean,
	};

	radioModel = '0';

	private readonly subs = new Subscription();


	// Show area total if its not zero OR if its zero and recurring service is also zero
	get showAreaTotal(): boolean {
		return this.optionTotalAmount !== 0 || ( this.optionTotalAmount === 0 && this.optionRecurringServiceTotal === 0 );
	}


	private onlyParentItems( items: IProposalAreaItemIdTypeModel[] ): IProposalAreaItemIdTypeModel[] {
		return items.map( ( item: IProposalAreaItemIdTypeModel ) => ( item.parentId === 0 ) ? item : null ).filter( n => n );
	}


	getItem( itemId: number ): IProposalAreaItemModel {
		return this.utils.findItemByKey( this.allItems, itemId, 'id' );
	}


	getNestedItems( parentItemId: number ): IProposalAreaItemModel[] {
		return this.allItems.filter( ( item: IProposalAreaItemModel ) => item && item.parentId === parentItemId );
	}


	getCheckedChangeOrderMiscItems( optionId: number ): { changeOrderId: number, changeOrderNumber: number, items: IProposalAreaItemModel[] }[] {
		return this.changeOrderService.getCheckedChangeOrderMiscItems( this.area?.id, optionId ) || [];
	}


	getOptionParentItemIdType( optionId: number ): IProposalAreaItemIdTypeModel[] {
		if ( this.parentItemIdTypesMap.has( optionId ) ) {
			return [ ...this.parentItemIdTypesMap.get( optionId ) ];
		}

		return [];
	}


	getMiscItemsOptionParentItemIdType( items: IProposalAreaItemModel[] ): IProposalAreaItemIdTypeModel[] {
		// Grab checked change order added items and create IdTypeModel from it
		const changeOrderMiscItemsIdTypeModel = items?.map( i => ( { id: i?.id, type: i?.type, parentId: i?.parentId } )) || [];

		return [ ...changeOrderMiscItemsIdTypeModel ];
	}


	// To determine if nested item is open or not - true/undefined/null = open, false = closed
	getNestedAreasState( value: boolean | undefined | null ): boolean {
		return ( value === undefined || value === null ) ? false : value;	// By default keep it closed
	}


	private getOptionById( optionId: number ): IProposalAreaOptionModel {
		return this.utils.findItemByKey( this.area.options, optionId, 'id' );
	}


	confirmingOption(): void {
		this.states.confirmingOptions = true;
	}


	cancelConfirmingOption(): void {
		this.states.confirmingOptions = false;
	}


	// Get string i.e options 2 & 3 OR options 1 & 2 ...
	get decliningOptionsString(): string {
		const optionsCount = [];
		let count = 0;
		for ( const option of this.area.options ) {
			optionsCount.push( ++count );
		}

		const index = optionsCount.indexOf( this.activeOptionCount );
		if ( index > -1 ) {
			optionsCount.splice( index, 1 );
		}

		return (optionsCount.length > 1 ? 'options ' : 'option ') + optionsCount.join(' & ');
	}


	ngOnChanges( change: SimpleChanges ) {
		if ( change?.['allItems'] || change?.['area'] ) {
			this.parentItemIdTypesMap.clear();

			if ( this.area && this.area.options ) {
				for ( const option of this.area.options ) {

					// Generate parent items
					const parentItemIdTypes = this.onlyParentItems( option.items );	// TODO: RECHECK LOGIC
					this.parentItemIdTypesMap.set( option.id, parentItemIdTypes );
				}

				if ( this.activeOptionId ) {
					const index = this.getOptionIndexById( this.activeOptionId );
					if ( index !== -1 ) {
						setTimeout( () => { this.selectOptionTab( index, this.activeOptionId, false ); }, 0 );
					}
				}


				this.getSelectedOption();
			}
		}
	}


	private getOptionIndexById( optionId: number ): number {
		return this.utils.findItemIndexByKey( this.area.options, optionId, 'id' );
	}


	// Get option client has selected
	getSelectedOption(): void {
		if ( this.area && this.area.options ) {
			let count = 0;
			for ( const option of this.area.options ) {
				count++;
				if ( option.status === AreaOptionStatusEnum.Accepted ) {
					this.clientSelectedOption = count;

					return;
				}
			}
		}

		this.clientSelectedOption = 0;
	}


	// Based on highlighted change orders
	get optionTotalCalculatedAmount(): number {
		return this.optionTotalAmount + this.changeOrderService.getCheckedChangeOrderAreaTotal( this.area.id, this.activeOptionId );
	}


	// Based on highlighted change orders
	get optionRecurringServiceCalculatedTotal(): number {
		return this.optionRecurringServiceTotal + this.changeOrderService.getCheckedChangeOrderRecurringServicesTotal( this.area.id, this.activeOptionId );
	}


	selectOptionTab( tabId: number, optionId: number, emit = true ) {
		if ( this.optionalItemTabs && this.optionalItemTabs.tabs && this.optionalItemTabs.tabs[tabId] ) {
			this.radioModel = String( tabId );
			this.optionalItemTabs.tabs[tabId].active = true;
			const option = this.getOptionById( optionId );
			if ( option && option?.financials !== undefined ) {
				this.optionTotalAmount           = option.financials.areaOptionTotal;
				this.optionRecurringServiceTotal = option.financials.recurringServiceTotal;
				this.activeOptionStatus          = option.status;
			}

			this.activeOptionCount = ( tabId + 1 );	// tabId starts with 0

			if ( emit ) {
				this.eventActiveOption.emit( optionId );
			}

			this.markForChange();
		}
	}


	onSelectTab( index: number ): void {
		this.radioModel = String( index );
	}


	confirmOption(): void {
		this.utils.clearPreviousErrors();

		if ( !this.area ) {
			this.utils.handleError( `Couldn't find Area ID` );
			return;
		}

		if ( !this.activeOptionId ) {
			this.utils.handleError( `Couldn't find Option ID` );
			return;
		}

		this.loaderStates.area = true;

		this.subs.add(
			this.proposalService.selectOption( this.area.id, this.activeOptionId, this.token ).subscribe(
				( data: any ) => {
					this.loaderStates.area = false;
					this.eventReloadParent.emit();
					this.markForChange();
				},
				( error: ErrorModel ) => {
					this.utils.handleError( error.message );
					this.loaderStates.area = false;
					this.markForChange();
				}
			)
		);
	}


	undo(): void {
		this.utils.clearPreviousErrors();

		if ( !this.area ) {
			this.utils.handleError( `Couldn't find Area ID` );
			return;
		}

		this.loaderStates.area = true;

		this.subs.add(
			this.proposalService.unselectOption( this.area.id, this.token ).subscribe(
				( data: any ) => {
					this.loaderStates.area = false;
					this.eventReloadParent.emit();
					this.markForChange();
				},
				( error: ErrorModel ) => {
					this.utils.handleError( error.message );
					this.loaderStates.area = false;
					this.markForChange();
				}
			)
		);
	}


	ngOnDestroy() {
		if ( this.subs ) {
			this.subs.unsubscribe();
		}
	}


	// Mark for change detection
	private markForChange(): void {
		this.cd.markForCheck();
	}

}
