import { Component, Input, ChangeDetectionStrategy } from '@angular/core';


@Component({
	selector: 'app-loader',
	template: `
		<div class="cssloader" *ngIf="circle"></div>
		<div class="dotloader-container" *ngIf="!circle">
			<div class="dot1"></div>
			<div class="dot2"></div>
			<div class="dot3"></div>
		</div>
	`,
	styleUrls: ['./loader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class LoaderComponent {
	@Input() show    : boolean = false;
	@Input() circle  : boolean = false;
	@Input() color   : string;
	@Input() position: string;
}
