import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, QueryList, ContentChildren, AfterContentInit, AfterContentChecked, inject } from '@angular/core';
import { ICurrencyModel } from '@app/core/models/common.models';

// Utilities
import { Utilities } from '@app/shared/utilities';
import { ImageUtils } from '@app/shared/image-utils';

// Components
import { ProposalRoomItemComponent } from '../proposal-room-item/proposal-room-item.component';

// Services
import { ProposalCommonService } from '@app/proposals/services/proposal-common.service';

// Enums
import { AreaItemTypeEnum, CloudinaryPathEnum } from '@app/core/enums';


@Component({
	selector       : 'app-proposal-room-nest',
	templateUrl    : './proposal-room-nest.component.html',
	styleUrls      : ['./proposal-room-nest.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone     : false,
})
export class ProposalRoomNestComponent implements AfterContentInit, AfterContentChecked {
	private readonly proposalCommonService = inject( ProposalCommonService );
	private readonly cd                    = inject( ChangeDetectorRef );
	public readonly utils                  = inject( Utilities );

	@ContentChildren(ProposalRoomItemComponent, { descendants: true }) items: QueryList<ProposalRoomItemComponent>;

	@Input() showItems     : boolean = false;		// By default collapsed in ProposalViewer
	@Input() hideItemPrices: boolean = false;
	@Input() hideImages    : boolean = false;
	@Input() combinePrice  : boolean = false;
	@Input() currency      : ICurrencyModel;
	@Input() parentId      : number;

	readonly AreaItemTypeEnum   = AreaItemTypeEnum;
	readonly CloudinaryPathEnum = CloudinaryPathEnum;
	readonly getProductThumbnailImageUrl = ImageUtils.getProductThumbnailImageUrl;

	numItems       : number = 0;
	totalGroupPrice: number = null;


	ngAfterContentInit(): void {
		this.getItemData();
	}


	// This is like ngOnChanges but for Content projections
	ngAfterContentChecked(): void {
		this.getItemData();
	}


	private getItemData(): void {
		if ( this.items && this.items?.length ) {
			this.numItems = this.items.length;

			// Flush price
			this.totalGroupPrice = null;

			for ( const item of this.items.toArray() ) {
				if ( item.recurringService !== true ) {
					if ( this.totalGroupPrice === null ) {
						this.totalGroupPrice = 0;
					}

					this.totalGroupPrice += item.totalPrice;
				}
			}
		}
	}


	placeholderImage( itemType: AreaItemTypeEnum ): string {
		if ( itemType === AreaItemTypeEnum.Labor ) {
			return CloudinaryPathEnum.LaborPlaceholder;
		} else if ( itemType === AreaItemTypeEnum.Fee ) {
			return CloudinaryPathEnum.FeePlaceholder;
		}

		return CloudinaryPathEnum.Placeholder;
	}


	toggleState(): void {
		this.proposalCommonService.setNestedAreasState( this.parentId, !this.showItems );
	}


	get hideTotal(): boolean {
		return this.showItems && this.totalGroupPrice !== null;
	}


	// Mark for change detection
	private markForChange(): void {
		this.cd.markForCheck();
	}

}
