import { AppSectionRefEnum } from '@app/core/enums';


// NOTE: Position in array represents change order number (i.e. 0 = change order 1, 1 = change order 2, etc.)
export const ChangeOrderIcons = [
	'favicon-1-blue.ico',
	'favicon-2-purple.ico',
	'favicon-3-teal.ico',
	'favicon-4-yellow.ico',
	'favicon-5-red.ico',
	'favicon-6-green.ico',
	'favicon-7-aqua.ico',
	'favicon-8-lime.ico',
	'favicon-9-magenta.ico',
	'favicon-10-cobalt.ico',
	'favicon-11-indigo.ico',
	'favicon-12-forest.ico',
	'favicon-13-maroon.ico',
	'favicon-14-sky.ico',
	'favicon-15-lavender.ico',
	'favicon-16-mint.ico',
];


export const MaxChangeOrderAvailableColors = 16;


export const SessionStorageKeys = {
	DownloadProcessIdKey: 'ViewerDownloadProcessId',
};


export const LinkAbbr: Record<AppSectionRefEnum, string> = {
	[AppSectionRefEnum.PaymentRequest]: 'REQ',
	[AppSectionRefEnum.Invoice]       : 'INV',
};
