import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { ProposalService } from '@app/core/services/proposal.service';
import { CommonService } from '@app/core/services/common.service';

// Directives
import { ModalDirective } from 'ngx-bootstrap/modal';

// Utilities
import { Utilities } from '@app/shared/utilities';
import { ErrorModel } from '@app/core/models/error.model';


@Component({
	selector   : 'app-request-changes-modal',
	templateUrl: './modal-request-changes.component.html',
	styleUrls  : ['./modal-request-changes.component.scss'],
	standalone : false,
})
export class RequestChangesModalComponent implements OnInit, OnDestroy {
	@ViewChild('modal', { static: true }) modal: ModalDirective;

	@Input() proposalId : number;
	@Input() token      : string;
	@Input() salesPerson: string;
	@Input() clientEmail: string;

	@Output() eventCloseModal  : EventEmitter<void>           = new EventEmitter();
	@Output() eventModalRef    : EventEmitter<ModalDirective> = new EventEmitter();
	@Output() eventReloadParent: EventEmitter<void>           = new EventEmitter();

	readonly alertContainer = 'requestChangesModal';

	comment          : string  = '';
	loader           : boolean = false;
	feedbackSubmitted: boolean = false;

	private subs: Subscription = new Subscription();


	constructor(
		private proposalService: ProposalService,
		private commonService  : CommonService,
		public utils           : Utilities,
	) { }


	ngOnInit() {
		// Emit a modal ref if parent component wants to use its properties
		this.eventModalRef.emit( this.modal );

		// Have to clear previous error as their states are stored globally
		this.utils.clearPreviousErrors( this.alertContainer );

		// Show modal once it has stablized little
		setTimeout( () => this.modal.show(), 10 );
	}


	ngOnDestroy() {
		if ( this.subs ) {
			this.subs.unsubscribe();
		}
	}


	public hideModal(): void {
		this.eventCloseModal.emit();
		this.modal.hide();
	}


	public hideLoading(): void {
		this.loader = false;
	}


	public showLoading(): void {
		this.loader = true;
	}


	requestChanges(): void {
		this.utils.clearPreviousErrors( this.alertContainer );
		this.showLoading();

		this.subs.add(
			this.proposalService.clientResponse( this.proposalId, this.comment, 'delayed', this.token, this.clientEmail ).subscribe(
				( res ) => {
					this.hideLoading();

					this.feedbackSubmitted = true;

					this.eventReloadParent.emit();
				},
				( error: ErrorModel ) => {
					this.utils.handleError( this.commonService.getErrorText( error ), { containerName: this.alertContainer } );

					this.hideLoading();
				}
			)
		);
	}

}
