import { Component, Input } from '@angular/core';

// Services
import { AlertService } from '@app/core/services/alert.service';

// Models
import { AlertModel } from '@app/core/models/alert.model';


@Component({
	selector   : 'app-alert-container',
	templateUrl: './alert-container.component.html',
	standalone : false,
})
export class AlertContainerComponent {

	@Input() containerName: string;


	constructor( private alertService: AlertService ) { }


	get alertList(): Set<AlertModel> {
		return this.alertService.getAlerts( this.containerName );
	}


	public closeAlert( alert: AlertModel ): void {
		this.alertService.closeAlert( alert, this.containerName );
	}

}
