import { Component, Input } from '@angular/core';

type modeTypes = 'info' | 'warning' | 'gray-scale' | 'light-gray' | 'green';


@Component({
	selector   : 'app-info-container',
	templateUrl: './info-container.component.html',
	styleUrls  : ['./info-container.component.scss'],
	standalone : false,
})
export class InfoContainerComponent {
	@Input() mode                 : modeTypes = 'info';
	@Input() showIcon             : boolean   = true;
	@Input() onlyContentProjection: boolean   = false;

}
