import { Component, Input, ChangeDetectionStrategy, inject } from '@angular/core';

// Models
import { IProposalClientSettingsModel, IProposalDealerModel, IProposalDetailClientModel, IProposalDetailModel, IProposalExpiryModel } from '@app/core/models/proposal.models';

// Enums
import { CloudinaryPathEnum, ProposalStatusEnum } from '@app/core/enums';

// Utilities
import { Utilities } from '@app/shared/utilities';
import { ImageUtils } from '@app/shared/image-utils';


@Component({
	selector       : 'app-simple-cover-page',
	templateUrl    : './cover-page.component.html',
	styleUrls      : ['./cover-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone     : false,
})
export class SimpleCoverPageComponent {
	public readonly utils = inject( Utilities );

	@Input({ required: true }) proposalStatus    : IProposalDetailModel['status'];
	@Input({ required: true }) proposalName      : IProposalDetailModel['name'];
	@Input({ required: true }) logoImageAssetId  : IProposalDetailModel['logoImageAssetId'];
	@Input({ required: true }) client            : IProposalDetailClientModel;
	@Input({ required: true }) dealer            : IProposalDealerModel;
	@Input({ required: true }) expiry            : IProposalExpiryModel;
	@Input({ required: true }) hideCompanyAddress: IProposalClientSettingsModel['hideCompanyAddress'];

	readonly CloudinaryPathEnum = CloudinaryPathEnum;
	readonly ProposalStatusEnum = ProposalStatusEnum;

	readonly getCompanyLogoImageUrl = ImageUtils.getCompanyLogoImageUrl;


	get hasClientData(): boolean {
		return !this.utils.checkIfObjectEmpty( this.client );
	}
}
