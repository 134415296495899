import { Component, Inject, TemplateRef } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

// Models
import { IMatSnackBarData } from '@app/core/models/snackbar.model';


@Component({
	selector   : 'app-snackbar',
	templateUrl: './snackbar-container.component.html',
	styleUrls  : ['./snackbar-container.component.scss'],
	standalone : false,
})
export class SnackbarContainerComponent {

	// Apply Classes when there is some action button, ( due to layout difference )
	readonly actionClasses = this.isActionSnackBar ? 'd-flex align-items-center justify-content-between' : '';


	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: IMatSnackBarData<any>,
		public matSnackBarRef: MatSnackBarRef<SnackbarContainerComponent>,
	) { }


	onDismiss(): void {
		this.matSnackBarRef?.dismiss();
	}


	get isMessageTemplate(): boolean {
		return typeof this.data?.message !== 'string';
	}


	get messageTemplate(): TemplateRef<any> {
		return this.data?.message as TemplateRef<any>;
	}


	get isButtonTemplate(): boolean {
		return typeof this.data?.actionButton !== 'string';
	}


	get buttonTemplate(): TemplateRef<any> {
		return this.data?.actionButton as TemplateRef<any>;
	}


	private get isActionSnackBar(): boolean {
		return !!this.data?.actionButton;
	}

}
