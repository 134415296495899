import { ChangeDetectionStrategy, Component } from '@angular/core';


@Component({
	selector       : 'app-br',
	template       : '',
	styleUrls      : ['./br.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone     : false,
})
export class BrComponent {
}
